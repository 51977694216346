<template>
    <div class="text-center">

        <loading-progress
            :indeterminate="indeterminate"
            :counter-clockwise="counterClockwise"
            :hide-background="hideBackground"
            rotate
            size="52"
            fillDuration="2"
            rotationDuration="10"
        />
        Loading ...
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            indeterminate: true,
            counterClockwise: false,
            hideBackground: false
        }
    }
}
</script>